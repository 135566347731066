import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import map from 'lodash/map';

import {
  Button, Checkbox, Row, Space,
} from 'antd';

import 'antd/es/button/style/index.css';
import 'antd/es/space/style/index.css';
import 'antd/es/checkbox/style/index.css';
import 'antd/es/grid/style/index.css';

import '../styles.css';
import './styles.css';

export interface StatusInfo {
  enabled: boolean;
  title: string;
}

interface Props {
  trendStatuses: Map<string, StatusInfo>;
  trendsToggled: (trends: [string, boolean][]) => void;
}

const propTypes = {
  trendStatuses: PropTypes.instanceOf(Map).isRequired,
  trendsToggled: PropTypes.func,
};

const TrendSelector = ({
  trendStatuses,
  trendsToggled,
}: Props) => {
  const { t } = useTranslation();

  const checkboxes = map(
    Array.from(trendStatuses.entries()),
    ([id, status]) => (
      <div className="trend-selector-checkbox">
        <Checkbox
          checked={status.enabled}
          onChange={(e): void => trendsToggled([[id, e.target.checked]])}
        >
          {status.title}
        </Checkbox>
      </div>
    ),
  );

  const toggleAll = (enabled: boolean): void => {
    const trends: [string, boolean][] = [];

    trendStatuses.forEach((status, id) => trends.push([id, enabled]));
    trendsToggled(trends);
  };

  return (
    <section className="trend-selector print-hideable">
      <Space className="trend-selector-buttons">
        <Button onClick={(): void => toggleAll(true)}>{t('trendsDashboard:select-all')}</Button>
        <Button onClick={(): void => toggleAll(false)}>{t('trendsDashboard:select-none')}</Button>
      </Space>
      <Row>{checkboxes}</Row>
    </section>
  );
};

TrendSelector.propTypes = propTypes;

export default TrendSelector;
