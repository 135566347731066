/* eslint react/jsx-props-no-spreading: 0 */
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import * as d3 from 'd3';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';
import isNaN from 'lodash/isNaN';
import get from 'lodash/get';
import clamp from 'lodash/clamp';

import map from 'lodash/map';
import reject from 'lodash/reject';
import { HIDE_GUIDE_TEXT_IDS } from '../constants';
import { getDataInRange, getLogData, setUpTrendGraph } from '../utils/graph';
import getLocalizedText from '../utils/getLocalizedText';
import {
  Point, TGraphData, TrendGraphProps, TrendSeries,
} from '../types';
import { TrendGraphPropTypes, TrendSeriesPropType } from '../propTypes';
import { handleTooltipMouseMove } from '../utils/tooltips';

import SvgTrendPlot from '../SvgTrendPlot';

import '../styles.css';
import './styles.css';

interface Props extends TrendGraphProps {
  trendSeries: TrendSeries;
}

const propTypes = {
  ...TrendGraphPropTypes,
  trendSeries: TrendSeriesPropType.isRequired,
};

const getTooltipText = (
  datum: TGraphData,
  trendSeries: TrendSeries,
  language: string,
): string => {
  const {
    labels,
    decimals = 0,
  } = trendSeries;

  const labelUnit = getLocalizedText(trendSeries, language, 'labelUnit');

  let labelValue = round(get(datum, 'labelValue', 0), decimals);

  if (isNaN(labelValue)) {
    labelValue = 0;
  }

  const labelText = get(labels, [labelValue, language]);

  if (labelText) {
    return labelText;
  }

  // Some trend indicators do not have labels, add labelUnit
  if (labelUnit) {
    return `${d3.format(`.${decimals}f`)(labelValue)} ${labelUnit}`;
  }

  // Return the value if the indicator has no labels or labelUnit
  return `${d3.format(`.${decimals}f`)(labelValue)}`;
};

const shouldShowGuideText = (trendSeries: TrendSeries): boolean => {
  if (trendSeries.graphConfig) {
    return trendSeries.graphConfig.showThumbs;
  }
  return !isEmpty(trendSeries.labels) && !includes(HIDE_GUIDE_TEXT_IDS, trendSeries.id);
};

const TrendGraph = (props: Props) => {
  const {
    endOfRange,
    timeRange,
    width,
    height,
    trendSeries,
    maximumValue,
    minimumValue,
    xPadding,
    yPadding,
  } = props;

  const { i18n } = useTranslation();
  const svgRef = useRef(null);
  const startOfRange = endOfRange - timeRange;
  let data = getDataInRange(trendSeries.data, startOfRange, endOfRange);
  let max = maximumValue;
  let min = minimumValue;

  // The PSA level uses Math.log on all values if there is a value > 40
  if (trendSeries.type === 'psa' || endOfRange === startOfRange) {
    const maxValue = endOfRange === startOfRange ? max : 40;
    (
      {
        graphData: data,
        maxValue: max,
        minValue: min,
      } = getLogData(data, maxValue, minimumValue)
    );
  }

  const {
    bisectDate,
    line,
    scaleX,
    scaleY,
    xAxis,
  } = setUpTrendGraph({
    timeRange,
    endOfRange,
    width,
    height,
    minimumValue: min,
    maximumValue: max,
    xPadding,
    yPadding,
  });

  const getSvg = () => d3.select(svgRef.current);

  const sortedSelectableGraphData = reject(data, ['selectable', false]);

  const points: Point[] = map(sortedSelectableGraphData, ({ date, value, labelValue }) => (
    new Point(
      clamp(scaleX(date) || 0, 0, width) as number,
      scaleY(value) as number,
      trendSeries.id,
      labelValue,
    )
  ));

  function onMousemove(this: any): void {
    handleTooltipMouseMove(
      this,
      scaleX,
      scaleY,
      bisectDate,
      [{ ...trendSeries, data }],
      getTooltipText,
      getSvg(),
      50,
      i18n.language,
      points,
    );
  }

  const onMouseOver = (): void => {
    const svg = getSvg();
    svg.select('.trend-graph-focus').style('display', null);
    svg.select('.trend-series').style('stroke-width', '3px');
  };

  const onMouseOut = (): void => {
    const svg = getSvg();
    svg.select('.trend-graph-focus').style('display', 'none');
    svg.select('.trend-series').style('stroke-width', '2px');
  };

  useEffect(() => {
    const svg = getSvg();

    (svg.select('.axis-line') as d3.Selection<
    SVGGElement,
    unknown,
    null,
    undefined
    >).call(xAxis);

    svg
      .select('.trend-series')
      .data([data])
      .attr('d', (d: any) => line(d));

    svg.select('.trend-graph-tooltip-overlay').on('mousemove', onMousemove);

    points.forEach((point, index) => {
      svg
        .select(`.trend-graph-focus-circle.index-${index}`)
        .attr('transform', `translate(${point.x}, ${point.y})`);
    });
  });

  const labelUnit = getLocalizedText(trendSeries.labelUnit, i18n.language);

  const circles = map(points, (_, index) => <circle key={index} className={`trend-graph-focus-circle index-${index}`} style={{ fill: trendSeries.color }} r={5} />);

  return (
    <section className="trend-graph">
      <h2 className="trend-title">{getLocalizedText(trendSeries, i18n.language, 'title')}</h2>
      <svg ref={svgRef} width={width} height={height}>
        <SvgTrendPlot
          width={width}
          height={height}
          xPadding={30}
          yPadding={20}
          showGuideText={shouldShowGuideText(trendSeries)}
          showTooltipTitle={false}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          labelUnit={labelUnit}
        >
          <path className="trend-series" style={{ stroke: trendSeries.color }} />
          {circles}
        </SvgTrendPlot>
      </svg>
    </section>
  );
};

TrendGraph.propTypes = propTypes;

export default TrendGraph;
