import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import * as d3 from 'd3';
import {
  addDays, addMonths, addWeeks, startOfDay, subDays, subMonths, subWeeks,
} from 'date-fns';
import moment from 'moment';

import {
  Button, DatePicker, Radio, Space,
} from 'antd';

import 'antd/es/button/style/index.css';
import 'antd/es/radio/style/index.css';
import 'antd/es/space/style/index.css';

import {
  DAY, FULL_DATE_FORMAT, MONTH, WEEK, YEAR,
} from '../constants';
import './styles.css';

interface Props {
  onRangeUpdate: (timeRange: number, endOfRange: number) => void;
  initialTimeRange?: number;
  initialEndOfRange: number;
  showTrendViews?: boolean;
  showDatePicker?: boolean;
}

const propTypes = {
  onRangeUpdate: PropTypes.func,
  initialTimeRange: PropTypes.number,
  initialEndOfRange: PropTypes.number,
  showTrendViews: PropTypes.bool,
  showDatePicker: PropTypes.bool,
};

const dateShiftFunctions = [
  [addDays, subDays],
  [addWeeks, subWeeks],
  [addMonths, subMonths],
];

const shiftDate = (timeRange: number, endOfRange: number, shiftRight: boolean): number => {
  let shiftFunctionIndex;
  let shiftAmount;

  switch (timeRange) {
    case WEEK:
      shiftFunctionIndex = 0;
      shiftAmount = 2;
      break;

    case MONTH:
      shiftFunctionIndex = 1;
      shiftAmount = 1;
      break;

    case YEAR:
      shiftFunctionIndex = 2;
      shiftAmount = 3;
      break;

    default:
      shiftFunctionIndex = 0;
      shiftAmount = Math.trunc((timeRange / 4) / DAY);
  }

  const signIndex = shiftRight ? 0 : 1;
  return dateShiftFunctions[shiftFunctionIndex][signIndex](endOfRange, shiftAmount).getTime();
};

const RangeController = ({
  onRangeUpdate,
  initialTimeRange = MONTH,
  initialEndOfRange,
  showTrendViews = true,
  showDatePicker = false,
}: Props) => {
  const { t } = useTranslation();
  const [{ timeRange, endOfRange }, setState] = useState({
    timeRange: initialTimeRange,
    endOfRange: initialEndOfRange,
  });

  const startOfRange = new Date(endOfRange - timeRange);
  const timeFormatter = d3.timeFormat(FULL_DATE_FORMAT);

  const updateRange = (newTimeRange: number, newEndOfRange: number) => {
    const clampedEndOfRange = Math.min(newEndOfRange, startOfDay(Date.now()).getTime());
    setState({
      timeRange: newTimeRange,
      endOfRange: clampedEndOfRange,
    });

    onRangeUpdate(newTimeRange, clampedEndOfRange);
  };

  const shiftDatesLeft = () => {
    updateRange(timeRange, shiftDate(timeRange, endOfRange, false));
  };

  const shiftDatesRight = () => {
    updateRange(timeRange, shiftDate(timeRange, endOfRange, true));
  };

  const onDateSelected = (date: Date | undefined) => {
    if (date) {
      updateRange(timeRange, date.getTime() + timeRange);
    }
  };

  return (
    <>
      <h2 className="date-header">
        {timeFormatter(startOfRange)} -{' '}
        {timeFormatter(new Date(endOfRange))}
      </h2>
      <Space>
        {showDatePicker
        && (
        <DatePicker
          onChange={(momentDate) => onDateSelected(momentDate?.toDate())}
          value={moment(startOfRange)}
          disabledDate={(date) => (date ? date.toDate().getTime() > Date.now() - timeRange : false)}
          format="DD/MM/YYYY"
        />
        )}

        <Button onClick={shiftDatesLeft}>{'  <  '}</Button>

        {showTrendViews && (
        <Radio.Group
          value={timeRange}
          buttonStyle="solid"
          onChange={(e) => updateRange(e.target.value, endOfRange)}
        >
          <Radio.Button value={WEEK}>{t('trendsDashboard:week')}</Radio.Button>
          <Radio.Button value={MONTH}>{t('trendsDashboard:month')}</Radio.Button>
          <Radio.Button value={YEAR}>{t('trendsDashboard:year')}</Radio.Button>
        </Radio.Group>
        )}

        <Button onClick={shiftDatesRight}>{'  >  '}</Button>
      </Space>
    </>
  );
};

RangeController.propTypes = propTypes;

export default RangeController;
