import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TOOLTIP_X_OFFSET } from '../utils/tooltips';

import './styles.css';
import '../styles.css';

interface SvgContentProps {
  height: number;
  width: number;
  xPadding: number;
  yPadding: number;
  showGuideText: boolean;
  showTooltipTitle: boolean;
  onMouseOver: () => void;
  onMouseOut: () => void;
  children: ReactNode;
  labelUnit?: string;
}

const propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  xPadding: PropTypes.number,
  yPadding: PropTypes.number,
  showGuideText: PropTypes.bool,
  showTooltipTitle: PropTypes.bool,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  children: PropTypes.node,
  labelUnit: PropTypes.string,
};

const SvgTrendPlot = ({
  height,
  width,
  xPadding,
  yPadding,
  showGuideText,
  showTooltipTitle,
  onMouseOver,
  onMouseOut,
  children,
  labelUnit = '',
}: SvgContentProps) => {
  const tooltipHeight = showTooltipTitle ? 75 : 50;
  const tooltipDateHeight = showTooltipTitle ? 18 : -2;
  const { t } = useTranslation();

  return (
    <>
      {children}
      <rect className="padding-rect" width={xPadding / 2} height={height} />
      <line
        className="center-line"
        transform={`translate(0, ${height / 2})`}
        x1={xPadding}
        x2={width - xPadding}
      />
      <g
        className="axis-line"
        transform={`translate(0, ${height - yPadding})`}
      />

      {showGuideText ? (
        <>
          <text
            className="guide-text"
            x={xPadding}
            y={height * 0.25}
            textAnchor="left"
          >
            {t('trendsDashboard:better')}
          </text>
          <text
            className="guide-text"
            x={xPadding}
            y={height * 0.75}
            textAnchor="left"
          >
            {t('trendsDashboard:worse')}
          </text>
        </>
      ) : labelUnit && (
        <>
          <text
            className="guide-text"
            x={0}
            y={10}
            textAnchor="left"
          >
            {labelUnit}
          </text>

          <line
            transform={`rotate(90) translate(0,${-30})`}
            className="separate-line"
            x1={20}
            x2={height * 0.92}
          />
        </>
      )}

      <g className="trend-graph-focus">
        <rect
          className="trend-graph-tooltip"
          height={tooltipHeight}
          x={TOOLTIP_X_OFFSET}
          y={-22}
          rx={4}
          ry={4}
        />
        {showTooltipTitle && <text className="trend-graph-tooltip-title" x={18} y={-2} />}
        <text className="trend-graph-tooltip-date" x={18} y={tooltipDateHeight} />
        <text className="trend-graph-tooltip-value" x={18} y={tooltipDateHeight + 20} />
      </g>

      <circle className="trend-graph-focus-circle" style={{ display: 'none' }} r={5} />

      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
      <rect
        className="trend-graph-tooltip-overlay"
        width={width}
        height={height}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      />
    </>
  );
};

SvgTrendPlot.propTypes = propTypes;

export default SvgTrendPlot;
