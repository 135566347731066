import { TLocalizedText } from './types';

export enum TrafficLight {
  Empty,
  Green,
  Amber,
  Red,
}

export const TrafficLightNames = new Map<TrafficLight, string>([
  [TrafficLight.Empty, 'empty'],
  [TrafficLight.Green, 'green'],
  [TrafficLight.Amber, 'amber'],
  [TrafficLight.Red, 'red'],
]);

export type Formula = {
  id: string;
  clinicalTitle: TLocalizedText;
  redFormula: FormulaExpression[];
  amberFormula: FormulaExpression[];
};

export type ValueExpression = {
  value: Range;
  duration?: number;
  maxInterval?: number;
};

export type CountExpression = {
  value: Range;
  count: Range;
  duration: number;
};

export type FormulaExpression = ValueExpression | CountExpression;

export type Range =
  | number
  | {
    from?: number;
    upTo?: number;
  };
