import { addHours, startOfDay } from 'date-fns';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import clamp from 'lodash/clamp';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import map from 'lodash/map';

import {
  CountExpression,
  Formula,
  FormulaExpression,
  Range,
  TrafficLight,
  ValueExpression,
} from '../clinical_types';
import { TGraphData } from '../types';

interface FormulaContext {
  data: TGraphData[];
  index: number;
  id: string;
}

export const evaluateRange = (value: number, range: Range): boolean => {
  if (typeof range === 'number') {
    return value === range;
  }

  let { from, upTo } = range;

  if (from === undefined) {
    from = -Number.MAX_VALUE;
  }

  if (upTo === undefined) {
    upTo = Number.MAX_VALUE;
  }

  return clamp(value, from, upTo) === value;
};

const hoursToMillis = (hours: number) => hours * 60 * 60 * 1000;

export const evaluateValueExpression = (
  { id, data, index }: FormulaContext,
  expression: ValueExpression,
): boolean => {
  if (!expression.duration) {
    return evaluateRange(data[index].value, expression.value);
  }

  if (!expression.maxInterval) {
    throw new Error(
      `"maxInterval" must be present in value-type expression with duration set, formula id ${id}`,
    );
  }

  const durationMillis = hoursToMillis(expression.duration);
  const startOfRange = data[index].date - durationMillis;
  const maxIntervalMillis = hoursToMillis(expression.maxInterval);
  let lastDate = data[index].date;
  let foundValueInRange = false;

  // TODO: testing

  for (let i = index; i >= 0; i -= 1) {
    if (data[i].date < lastDate - maxIntervalMillis) {
      return false;
    }

    lastDate = data[i].date;
    foundValueInRange = evaluateRange(data[i].value, expression.value);

    if (!foundValueInRange) {
      return false;
    }

    if (data[i].date <= startOfRange) {
      return foundValueInRange;
    }
  }

  return false;
};

export const evaluateCountExpression = (
  { data, id, index }: FormulaContext,
  expression: CountExpression,
): boolean => {
  if (expression.duration === undefined) {
    throw new Error(
      `"duration" missing from count-type expression for formula id "${id}"`,
    );
  }

  const durationMillis = expression.duration * 60 * 60 * 1000;
  const startOfRange = data[index].date - durationMillis;
  let count = 0;

  for (let i = index; i >= 0; i -= 1) {
    if (data[i].date < startOfRange) {
      break;
    }

    if (evaluateRange(data[i].value, expression.value)) {
      count += 1;
    }
  }

  return evaluateRange(count, expression.count as Range);
};

export const evaluateExpression = (
  formulaContext: FormulaContext,
  expression: FormulaExpression,
): boolean => {
  if ('count' in expression) {
    return evaluateCountExpression(
      formulaContext,
      expression as CountExpression,
    );
  }
  return evaluateValueExpression(formulaContext, expression as ValueExpression);
};

export const evaluateFormula = (
  formulaContext: FormulaContext,
  expressions: FormulaExpression[],
): boolean => {
  for (let i = 0; i < expressions.length; i += 1) {
    const result = evaluateExpression(formulaContext, expressions[i]);

    if (result) {
      return true;
    }
  }
  return false;
};

export const getTrafficLight = (
  data: TGraphData[],
  index: number,
  formula: Formula,
): TrafficLight => {
  const formulaContext = {
    data,
    index,
    id: formula.id,
  };

  if (evaluateFormula(formulaContext, formula.redFormula)) {
    return TrafficLight.Red;
  }

  if (evaluateFormula(formulaContext, formula.amberFormula)) {
    return TrafficLight.Amber;
  }

  return TrafficLight.Green;
};

export const generateTrafficLights = (
  data: TGraphData[],
  formula: Formula,
  start: number,
  end: number,
): TrafficLight[] => {
  const trafficLights = new Map<number, TrafficLight>(
    map(
      eachDayOfInterval({
        start: addHours(startOfDay(start), 12).getTime(),
        end: addHours(startOfDay(end), 12).getTime(),
      }),
      (date) => [date.getTime(), TrafficLight.Empty],
    ),
  );

  const explicitData = filter(data, (datum) => !datum.implicit);

  forEach(explicitData, (datum, index) => {
    const day = startOfDay(datum.date).getTime();
    const existingLight = trafficLights.get(day);

    if (existingLight === undefined) {
      return;
    }

    const trafficLight = getTrafficLight(explicitData, index, formula);

    if (trafficLight > existingLight) {
      trafficLights.set(day, trafficLight);
    }
  });

  return Array.from(trafficLights.values());
};
