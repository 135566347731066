import takeRight from 'lodash/takeRight';

import { getRoute } from '../routes';

export const getDocumentIdAndKey = (): { documentId: string, key: string } => {
  const ids = takeRight(window.location.href.split('/'), 2) || ['', ''];
  return { documentId: ids[0], key: ids[1] };
};

export const getRouteWithShareKey = (route: string) => {
  const { documentId, key } = getDocumentIdAndKey();
  return getRoute(route, documentId, key);
};

export const inDev = (): boolean => process.env.NODE_ENV === 'development';
