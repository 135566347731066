import keys from 'lodash/keys';

export const fallbackLng = 'en-GB';

type SupportedLocalesType = {
  [key: string]: any;
};

export const supportedLocales: SupportedLocalesType = {
  'en-GB': {
    name: 'English (UK)',
    translationFileLoader: (namespace: string) => {
      switch (namespace) {
        case 'trendsDashboard':
          return require('../../languages/en-GB/trendsDashboard.json'); // eslint-disable-line global-require
        default:
      }

      return {};
    },
    dateLocaleLoader: () => import('date-fns/locale/en-GB'),
  },
  'nl-NL': {
    name: 'Nederlands',
    translationFileLoader: (namespace: string) => {
      switch (namespace) {
        case 'trendsDashboard':
          return require('../../languages/nl-NL/trendsDashboard.json'); // eslint-disable-line global-require
        default:
      }

      return {};
    },
    dateLocaleLoader: () => import('date-fns/locale/nl'),
  },
};

export const defaultNamespace = 'trendsDashboard';

export const allowedLanguages = keys(supportedLocales);

export const namespaces = ['trendsDashboard'];
