import React, { Suspense } from 'react';

import {
  BrowserRouter, Redirect, Route, Switch,
} from 'react-router-dom';

import './services/i18n';
import routes, { BC_DOCUMENT_ID, getRoute } from './routes';

import TrendDataSource from './screens/TrendDataSource';
import logo from './logo.png';

import './App.css';
import './components/TrendGraphs/styles.css';

const extractSharekey = () => {
  const path = window.location.href.replace(window.location.origin, '');
  const pathResources = path.split('/');
  return pathResources[pathResources.length - (path.endsWith('/') ? 2 : 1)];
};

const App = (): JSX.Element => {
  const shareKey = extractSharekey();

  return (
    <BrowserRouter>
      <Suspense fallback="loading">
        <article className="app">
          <div className="centered-wrapper">
            <img className="logo" src={logo} alt="OWise" />
          </div>

          <Switch>
            <Route exact path={routes.clinicalDasbhoard}>
              <TrendDataSource showClinical />
            </Route>
            <Route exact path={routes.bcClinicalDashboard}>
              <Redirect to={getRoute(routes.clinicalDasbhoard, BC_DOCUMENT_ID, shareKey)} />
            </Route>
            <Route exact path={routes.trendsDashboard}>
              <TrendDataSource showClinical={false} />
            </Route>
            <Route exact path={routes.bcTrendsDashboard}>
              <Redirect to={getRoute(routes.trendsDashboard, BC_DOCUMENT_ID, shareKey)} />
            </Route>
            <Route>
              <div className="centered-wrapper">
                <h1>
                  Share link not found or expired. Please check the link you received and try again.
                </h1>
              </div>
            </Route>
          </Switch>
        </article>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
