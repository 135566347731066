import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import includes from 'lodash/includes';
import map from 'lodash/map';
import size from 'lodash/size';
import filter from 'lodash/filter';

import { List, Radio, Space } from 'antd';

import 'antd/es/list/style/index.css';

import { isArray } from 'lodash';
import { TrendSeries, TTrendDataCustomType } from '../types';
import getLocalizedText from '../utils/getLocalizedText';

import TrendSelector, { StatusInfo } from '../TrendSelector';
import TrendGraph from '../TrendGraph';
import CombinedTrendGraph from '../CombinedTrendGraph';
import RangeController from '../RangeController';

import '../styles.css';

interface Props {
  onRangeUpdate: (timeRange: number, endOfRange: number) => void;
  timeRange: number;
  endOfRange: number;
  enabledTrends: string[];
  trendSeriesList: TrendSeries[];
  customDataSource?: TTrendDataCustomType;
}

const propTypes = {
  onRangeUpdate: PropTypes.func,
  timeRange: PropTypes.number,
  endOfRange: PropTypes.number,
  enabledTrends: PropTypes.arrayOf(PropTypes.string).isRequired,
  trendSeriesList: PropTypes.arrayOf(PropTypes.object),
};

// Get only trends using a 1-5 or 1-10 scale.
const getCombinedTrendSeriesList = (
  trendSeriesList: TrendSeries[],
): TrendSeries[] => trendSeriesList.map((trendSeries) => {
  if (trendSeries.maximumValue === 10) {
    return trendSeries;
  }

  const data = trendSeries.data.map((datum) => ({
    ...datum,
    originalValue: datum.value,
    value: (datum.value / trendSeries.maximumValue) * 10,
  }));

  return { ...trendSeries, data, maximumValue: 10 };
});

const getRelevantStatuses = (
  relevantSeriesList: TrendSeries[],
  statuses: Map<string, StatusInfo>,
  language: string,
): Map<string, StatusInfo> => {
  const relevantStatuses = new Map();

  relevantSeriesList.forEach((trendSeries) => relevantStatuses.set(
    trendSeries.id,
    statuses.get(trendSeries.id) || {
      enabled: true,
      title: getLocalizedText(trendSeries, language, 'title'),
    },
  ));

  return relevantStatuses;
};

const TrendsDashboard = ({
  onRangeUpdate,
  enabledTrends,
  trendSeriesList,
  timeRange,
  endOfRange,
  customDataSource,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [splitView, setSplitView] = useState(true);

  const [statuses, setStatuses] = useState(undefined as undefined | Map<string, StatusInfo>);

  const isClientDataSource = customDataSource === 'client';

  const filteredTrendSeries = isClientDataSource
    ? filter(trendSeriesList, (el) => size(el.data)) as TrendSeries[]
    : trendSeriesList;

  if (!statuses) {
    const defaultTrendStatuses = new Map<string, StatusInfo>(
      map(filteredTrendSeries, (trendSeries) => [
        trendSeries.id,
        {
          enabled: includes(enabledTrends, trendSeries.id),
          title: getLocalizedText(trendSeries, i18n.language, 'title'),
        },
      ]),
    );

    setStatuses(defaultTrendStatuses);
    return <></>;
  }

  let relevantSeriesList: TrendSeries[];
  let relevantStatuses: Map<string, StatusInfo>;

  if (!splitView) {
    relevantSeriesList = getCombinedTrendSeriesList(filteredTrendSeries);
    relevantStatuses = getRelevantStatuses(
      relevantSeriesList,
      statuses,
      i18n.language,
    );
  } else {
    relevantSeriesList = trendSeriesList;
    relevantStatuses = new Map(statuses);
  }

  const enabledTrendSeriesList = relevantSeriesList.filter(
    (trendSeries) => statuses.get(trendSeries.id)?.enabled,
  );

  const toggleTrends = (trends: [string, boolean][]): void => {
    if (!statuses) {
      return;
    }

    const newTrendStatuses = new Map(statuses);
    trends.forEach(([id, enabled]) => {
      const { title } = newTrendStatuses.get(id) as StatusInfo;
      newTrendStatuses.set(id, { enabled, title });
    });
    setStatuses(newTrendStatuses);
  };

  const getRange = (trendSeries: TrendSeries | TrendSeries[]) => {
    if (!isClientDataSource || !size(trendSeries)) {
      return {
        endOfRange,
        timeRange,
      };
    }

    if (splitView && !isArray(trendSeries)) {
      const trendData = trendSeries.data;
      return {
        endOfRange: trendData[trendData.length - 1].date,
        timeRange: trendData[trendData.length - 1].date - trendData[0].date,
      };
    }

    if (isArray(trendSeries)) {
      const firstItemData = trendSeries[0].data;

      return {
        endOfRange: firstItemData[firstItemData.length - 1].date,
        timeRange: firstItemData[firstItemData.length - 1].date - firstItemData[0].date,
      };
    }

    return {
      endOfRange,
      timeRange,
    };
  };

  const { innerWidth } = window;

  return (
    <section className="trends-dashboard">
      <section className="trends-panel centered-wrapper separator-div print-hideable">
        {
          !isClientDataSource
            ? (
              <RangeController
                onRangeUpdate={onRangeUpdate}
                initialTimeRange={timeRange}
                initialEndOfRange={endOfRange}
              />
            )
            : null
        }
        <section className="split-view-panel">
          <Space>
            <Radio.Group
              defaultValue={splitView}
              buttonStyle="solid"
              onChange={(e) => setSplitView(e.target.value)}
            >
              <Radio.Button value={false}>
                {t('trendsDashboard:combined')}
              </Radio.Button>
              <Radio.Button value>
                {t('trendsDashboard:separate')}
              </Radio.Button>
            </Radio.Group>
          </Space>
        </section>
      </section>
      <section className="centered-wrapper">
        <TrendSelector
          trendStatuses={relevantStatuses}
          trendsToggled={toggleTrends}
        />
      </section>
      { splitView
        ? (
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 2,
              xl: 2,
              xxl: 2,
            }}
            dataSource={enabledTrendSeriesList}
            renderItem={(trendSeries) => (
              <List.Item>
                <TrendGraph
                  trendSeries={trendSeries}
                  endOfRange={getRange(trendSeries).endOfRange}
                  timeRange={getRange(trendSeries).timeRange}
                  minimumValue={trendSeries.minimumValue}
                  maximumValue={trendSeries.maximumValue}
                  xPadding={30}
                  yPadding={20}
                  width={Math.min(475, innerWidth * 0.9)}
                  height={250}
                />
              </List.Item>
            )}
          />
        )
        : (
          <CombinedTrendGraph
            endOfRange={getRange(enabledTrendSeriesList).endOfRange}
            timeRange={getRange(enabledTrendSeriesList).timeRange}
            trendSeriesList={enabledTrendSeriesList}
            minimumValue={0}
            maximumValue={10}
            xPadding={30}
            yPadding={20}
            width={Math.min(750, innerWidth * 0.9)}
            height={400}
          />
        ) }
    </section>
  );
};

TrendsDashboard.whyDidYouRender = true;
TrendsDashboard.propTypes = propTypes;

export default TrendsDashboard;
