import React, { useEffect, useRef, useState } from 'react';

import { filter, map } from 'lodash';
import * as d3 from 'd3';
import { startOfDay, subDays } from 'date-fns';

import { DAY, WEEK } from '../constants';
import { Formula, TrafficLight, TrafficLightNames } from '../clinical_types';
import { generateTrafficLights } from '../utils/clinical_formula';
import formulas from '../data/clinical_dashboard.json';
import { TrendSeries } from '../types';
import { getFixedScaleX, getXAxis } from '../utils/graph';
import { handleClinicalTooltipMouseMove, hideElement } from '../utils/tooltips';

import './styles.css';
import '../styles.css';
import RangeController from '../RangeController';

interface LightProps {
  className: string;
}

const Light = ({ className }: LightProps): JSX.Element => <div className={`light ${className}`} />;

interface Props {
  trendSeriesList: TrendSeries[];
}

const ClinicalDashboard = ({ trendSeriesList }: Props): JSX.Element => {
  const [endOfRange, setEndOfRange] = useState(Date.now());
  const svgRef = useRef(null);
  const endDay = startOfDay(endOfRange);
  const endDayTime = endDay.valueOf();
  const days = 29;
  const startOfRange = subDays(endDay, days).getTime();

  const lightWidth = 22;
  const lightHeight = 36;
  const axisWidth = (days + 1) * lightWidth;

  const scaleX = getFixedScaleX(
    startOfRange - DAY / 2,
    endDayTime + DAY / 2,
    axisWidth,
    0,
  );

  const xAxis = getXAxis(scaleX, WEEK).ticks(d3.timeWeek.every(1));

  const formulaMap = new Map<string, Formula>(
    map(formulas.formulas, (formula) => [formula.id, formula]),
  );

  const relevantTrendSeriesList = filter(trendSeriesList,
    (trendSeries) => formulaMap.has(trendSeries.id));

  const trafficLightGrid = [] as TrafficLight[][];

  const tooltipWidth = 90;
  const tooltipHeight = 50;
  const tableHeaderWidth = 170;

  function onMouseMove(this: any) {
    handleClinicalTooltipMouseMove(
      this,
      tableHeaderWidth,
      lightWidth,
      lightHeight,
      trafficLightGrid,
      tooltipWidth,
      tooltipHeight,
      startOfRange,
    );
  }

  useEffect(() => {
    (d3.select(svgRef.current).select('.axis-line') as d3.Selection<
    SVGGElement,
    unknown,
    null,
    undefined
    >).call(xAxis);

    d3.select('.clinical-table').on('mousemove', onMouseMove);
    d3.select('.clinical-tooltip-svg').style('display', 'none');
  });

  const tableRows = relevantTrendSeriesList.map(
    (trendSeries, trendSeriesIndex) => {
      const formula = formulaMap.get(trendSeries.id) as Formula;

      const trafficLights = generateTrafficLights(
        trendSeries.data,
        formula,
        startOfRange,
        endDayTime,
      );

      trafficLightGrid.push(trafficLights);

      const lights = map(trafficLights, (trafficLight, index) => (
        <td className="clinical-table-td" key={index}>
          <Light className={`${TrafficLightNames.get(trafficLight)}-light`} />
        </td>
      ));

      return (
        // eslint-disable-next-line react/no-array-index-key
        <tr key={trendSeriesIndex}>
          <th className="clinical-table-header">
            <h4>{formula.clinicalTitle['en-GB']}</h4>
          </th>
          {lights}
        </tr>
      );
    },
  );

  const onRangeUpdate = (timeRange: number, newEndOfRange: number) => {
    setEndOfRange(newEndOfRange);
  };

  return (
    <section className="centered-wrapper">
      <section className="clinical-range-header">
        <RangeController
          onRangeUpdate={onRangeUpdate}
          initialTimeRange={days * DAY}
          initialEndOfRange={endOfRange}
          showTrendViews={false}
          showDatePicker
        />
      </section>
      <section className="clinical-section">
        {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
        <table
          className="clinical-table"
          cellPadding={0}
          cellSpacing={0}
          onMouseOut={() => hideElement('clinical-tooltip-svg')}
        >
          <tbody>{tableRows}</tbody>
        </table>
        <svg
          className="clinical-tooltip-svg"
          width={tooltipWidth}
          height={tooltipHeight}
        >
          <g>
            <circle
              className="trend-graph-focus-circle"
              r={5}
              transform={`translate(${tooltipWidth / 2}, 5)`}
            />
            <rect
              className="trend-graph-tooltip"
              width={tooltipWidth}
              height={30}
              transform="translate(0, 20)"
            />
            <text className="clinical-date-tooltip" transform="translate(10, 40)" />
          </g>
        </svg>
        <svg className="clinical-axis-svg" ref={svgRef} width={axisWidth} height={40}>
          <g className="axis-line" transform="translate(10, 0)" />
        </svg>
      </section>
    </section>
  );
};

export default ClinicalDashboard;
