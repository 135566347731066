import { LanguageDetectorAsyncModule } from 'i18next';

const defaultLanguage = 'en-GB';

const languageDetector: LanguageDetectorAsyncModule = {
  type: 'languageDetector',
  async: true,
  init: () => {},
  detect: async (callback) => callback(defaultLanguage),
  cacheUserLanguage: () => {},
};

export default languageDetector;
