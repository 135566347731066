/* eslint no-param-reassign: "off" */
import startOfDay from 'date-fns/startOfDay';
import filter from 'lodash/filter';
import map from 'lodash/map';
import first from 'lodash/first';
import last from 'lodash/last';
import orderBy from 'lodash/orderBy';
import sum from 'lodash/sum';
import round from 'lodash/round';
import mean from 'lodash/mean';
import toNumber from 'lodash/toNumber';
import maxBy from 'lodash/maxBy';
import * as d3 from 'd3';

import {
  Aggregation,
  TGraphData, TrendGraphProps, TrendSeries, TTrendDataCustomType,
} from '../types';
import { MONTH, YEAR } from '../constants';

export const getStartDate = (data: TGraphData[]): number => {
  const { date } = first(data) as TGraphData;

  return date;
};

export const getEndDate = (data: TGraphData[]): number => {
  const { date } = last(data) as TGraphData;

  return date;
};

const aggregateData = (data: TGraphData[], aggregation: Aggregation): TGraphData[] => {
  const dataByDay = new Map<number, number[]>();
  let lastDayStart = -1;

  data.forEach((datum) => {
    const dayStart = startOfDay(datum.date).valueOf();

    if (dayStart === lastDayStart) {
      const dataForDay = dataByDay.get(dayStart) as number[];
      dataForDay.push(datum.value);
    } else {
      dataByDay.set(dayStart, [datum.value]);
    }

    lastDayStart = dayStart;
  });

  const aggregateValues = (values: number[]): number => {
    if (aggregation === 'cumulative') {
      return sum(values);
    }
    if (aggregation === 'average') {
      return round(mean(values));
    }
    return last(values) || 0;
  };

  return Array.from(dataByDay.entries()).map(
    ([date, values]) => {
      const value = aggregateValues(values);
      return {
        date,
        value,
        labelValue: value,
        implicit: false,
      };
    },
  );
};

// Performs conversion, sorting, filtering, and pre-processing to get data into
// format ready for display.
export const processTimeData = (
  trendSeriesList: TrendSeries[],
  startDate: number,
  custom?: TTrendDataCustomType,
): void => {
  const forwardsDate = Date.now();

  trendSeriesList.forEach((trendSeries) => {
    let lastValue = trendSeries.defaultValue;

    trendSeries.data = filter(trendSeries.data, (datum) => !datum.implicit);

    trendSeries.data = orderBy(trendSeries.data, ['date'], ['asc']);

    if (custom === 'client') {
      if (trendSeries.data.length === 1) {
        trendSeries.data.push({ ...trendSeries.data[0], implicit: true, selectable: false });
      }

      trendSeries.data.forEach((datum) => {
        datum.date *= 1000;
        datum.labelValue = datum.value;
      });

      return;
    }

    trendSeries.data.forEach((datum) => {
      datum.date *= 1000;
    });

    trendSeries.data = aggregateData(
      trendSeries.data,
      trendSeries.graphConfig?.data || 'latest',
    );

    if (trendSeries.data.length > 0) {
      lastValue = (last(trendSeries.data) as TGraphData).value;
    }

    trendSeries.data.unshift({
      date: startDate,
      value: trendSeries.defaultValue,
      labelValue: trendSeries.defaultValue,
      selectable: false,
    });

    trendSeries.data.push({
      date: forwardsDate,
      value: lastValue,
      labelValue: lastValue,
      selectable: false,
    });
  });
};

// Returns all the data within the specified range as well as the first data point before and after
// the range.
export const getDataInRange = (
  data: TGraphData[],
  startDate: number,
  endDate: number,
): TGraphData[] => {
  if (data.length <= 2) {
    return Array.from(data);
  }

  // TODO - edge cases

  const relevantData: TGraphData[] = [];

  for (let i = 0; i < data.length; i += 1) {
    const { date } = data[i];

    if (date < startDate) {
      relevantData[0] = data[i];
    } else {
      relevantData.push(data[i]);

      if (date > endDate) {
        break;
      }
    }
  }

  return relevantData;
};

export const getFixedScaleX = (
  startDate: number,
  endDate: number,
  width: number,
  horizontalPadding: number,
): d3.ScaleTime<number, number> => d3
  .scaleTime()
  .domain([startDate, endDate])
  .range([horizontalPadding, width - horizontalPadding]);

export const getScaleX = (
  data: TGraphData[],
  width: number,
  horizontalPadding: number,
): d3.ScaleTime<number, number> => {
  const startDate = getStartDate(data);
  const endDate = getEndDate(data);

  return getFixedScaleX(startDate, endDate, width, horizontalPadding);
};

export const getScaleY = (
  height: number,
  min: number,
  max: number,
  verticalPadding: number,
): d3.ScaleLinear<number, number> => d3
  .scaleLinear()
  .domain([min, max])
  .range([height - verticalPadding, verticalPadding]);

// Wraps d3.timeFormat formatters such that they can accept a number or a value rather than just a date.
// This allows time formatters to be used for example in axis.tickFormat(...).
export const wrapTimeFormatter = (
  timeFormatter: (date: Date) => string,
): (
  domainValue: number | Date | { valueOf(): number },
  index: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ) => string => (domainValue, index) => {
  if (domainValue instanceof Date) {
    return timeFormatter(domainValue);
  }

  if (typeof domainValue === 'number') {
    return timeFormatter(new Date(domainValue));
  }

  return timeFormatter(new Date(domainValue.valueOf()));
};

// Adds a datapoint at the beginning and end of each series that copies the earliest
// and latest values and extends their dates.
export const scaleRange5ToRange10 = (value: number): number => 1 + (value - 1) * 2;

export const scaleRange10ToRange5 = (value: number): number => Math.round((value - 1) / 2) + 1;

export const getTimeFormatting = (
  timeRange: number,
): {
  timeFormat: string;
  timeAxisStep: d3.TimeInterval | null;
} => {
  let timeFormat = '%e %b';
  let timeAxisStep: d3.TimeInterval | null;

  if (timeRange >= YEAR) {
    timeFormat = '%b %Y';
    timeAxisStep = d3.timeMonth.every(3);
  } else if (timeRange >= MONTH) {
    timeAxisStep = d3.timeWeek.every(1);
  } else {
    timeAxisStep = d3.timeDay.every(2);
  }

  return { timeAxisStep, timeFormat };
};

export const getXAxis = (
  scaleX: d3.ScaleTime<number, number>,
  timeRange: number,
) => {
  const { timeAxisStep, timeFormat } = getTimeFormatting(timeRange);
  return d3
    .axisBottom(scaleX)
    .ticks(timeAxisStep)
    .tickFormat(wrapTimeFormatter(d3.timeFormat(timeFormat)))
    .tickSizeOuter(0);
};

interface D3GraphInfo {
  scaleX: d3.ScaleTime<number, number>;
  scaleY: d3.ScaleLinear<number, number>;

  xAxis: d3.Axis<number | Date | { valueOf(): number }>;

  line: d3.Line<[number, number]>;

  bisectDate: d3.Bisector<TGraphData, unknown>;
}

export const setUpTrendGraph = ({
  endOfRange,
  height,
  maximumValue,
  minimumValue,
  timeRange,
  width,
  xPadding,
  yPadding,
}: TrendGraphProps): D3GraphInfo => {
  const scaleX = getFixedScaleX(
    endOfRange - timeRange,
    endOfRange,
    width,
    xPadding,
  );
  const scaleY = getScaleY(height, minimumValue, maximumValue, yPadding);

  const xAxis = getXAxis(scaleX, timeRange);

  const line = d3
    .line()
    .x((d: any) => scaleX(d.date) || d.date)
    .y((d: any) => scaleY(d.value) || d.value)
    .curve(d3.curveMonotoneX);

  const bisectDate = d3.bisector((d: TGraphData) => d.date);

  return {
    scaleX, scaleY, xAxis, line, bisectDate,
  };
};

const mathLog = (value: number) => {
  // Math.log(0) returns -Infinity, so let's pick the lowest value
  // that returns something meaningful
  if (value === 0) {
    return Math.log(0.1);
  }

  return Math.log(value);
};

export const getLogData = (graphData: TGraphData[], max: number, min: number) => {
  // Get the maximum value of the graph data
  // Note that we add 10%
  const maxValue = toNumber(maxBy(map(graphData, 'value'))) * 1.1;

  // If maxValue <= max, return all data as-is
  if (maxValue <= max) {
    return {
      graphData,
      maxValue,
      // We can safely return 0 here, as there we don't Math.log on the data
      minValue: min,
    };
  }

  // If max > max, use Math.log on all values
  // Note that we also use Math.log for maxValue
  return {
    graphData: map(graphData, (data) => ({
      ...data,
      value: mathLog(data.value),
    })),
    maxValue: mathLog(maxValue),
    minValue: mathLog(min),
  };
};
