import * as d3 from 'd3';

export const FULL_DATE_FORMAT = '%e %b %Y';
export const MONTH_DAY_FORMAT = '%e %b';

export const HIDE_GUIDE_TEXT_IDS = [
  '0000000b-0000-1000-a000-000000000034', // stool
  '0000000b-0000-1000-a000-000000000036', // urine colour
  '0000000b-0000-1000-a000-000000000038', // urination
  '0000000b-0000-1000-a000-00000000003c', // vomiting
  '0000000b-0000-1000-a000-00000000003e', // bleeding
  '0000000b-0000-1000-a000-00000000000b', // low mood
];

export const DEFAULT_LANGUAGE = 'en-GB';
export const AVAILABLE_LANGUAGES = [DEFAULT_LANGUAGE, 'nl-NL'];

export const FORMAT_TOOLTIP_DATE = d3.timeFormat(FULL_DATE_FORMAT);

export const DAY = 1000 * 60 * 60 * 24;
export const WEEK = DAY * 7;
export const MONTH = DAY * 30;
export const YEAR = DAY * 365;
