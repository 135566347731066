export default {
  bcTrendsDashboard: '/:shareId',
  bcClinicalDashboard: '/clinical/:shareId',
  trendsDashboard: '/:documentId/:shareId',
  clinicalDasbhoard: '/clinical/:documentId/:shareId',
};

export const getRoute = (route: string, documentId: string, shareId: string) => route
  .replace(':documentId', documentId)
  .replace(':shareId', shareId);

export const BC_DOCUMENT_ID = 'f4312562-6107-4d2c-938a-fcd4aefe1a8e';
export const PC_DOCUMENT_ID = 'ab854cda-e48c-4c4c-a120-eb4ab9b38168';
