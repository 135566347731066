import React, { ReactNode, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Button } from 'antd';

import './styles.css';

interface Props {
  title: string;
  children?: ReactNode;
}

const propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

const printPage = (): void => {
  const hideableElements = document.getElementsByClassName('print-hideable') as HTMLCollectionOf<HTMLDivElement>;

  for (let i = 0; i < hideableElements.length; i += 1) {
    hideableElements[i].style.display = 'none';
  }

  window.print();

  for (let i = 0; i < hideableElements.length; i += 1) {
    hideableElements[i].style.display = 'block';
  }
};

const PageHeader = ({ title, children }: Props) => {
  const { t } = useTranslation();

  const printRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.code === 'KeyP' && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        event.stopPropagation();

        if (printRef?.current) {
          printRef.current.click();
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <section className="trends-report-div print-hideable">
      <section className="centered-wrapper">
        <section className="title-area">
          <h1 className="page-title">{title}</h1>
          <Button ref={printRef} className="print-button" type="primary" onClick={printPage}>
            {t('trendsDashboard:printPage')}
          </Button>
        </section>
        {children}
      </section>
    </section>
  );
};

PageHeader.propTypes = propTypes;

export default PageHeader;
