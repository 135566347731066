import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import get from 'lodash/get';

const getLocalizedText = (
  object: object | undefined,
  language: string,
  path?: string | number | undefined,
) => {
  if (isEmpty(object) || !isPlainObject(object)) {
    return '';
  }

  if (!path) {
    return get(object, [language]);
  }

  return get(object, [path, language]);
};

export default getLocalizedText;
